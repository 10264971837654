import React, { useState } from "react";

import "./styles.css";
import bg from "../../assets/videos/bg.mp4";
import {
  SlArrowDown,
  SlControlPause,
  SlControlPlay,
  SlVolume2,
  SlVolumeOff,
} from "react-icons/sl";
const BackgroundVideo = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);

  console.log(isPlaying);
  // const handlePlayPauseClick = () => {
  //   setIsPlaying(!isPlaying);
  //   const video = document.querySelector("video");
  //   if (!isPlaying) {
  //     video.play();
  //   } else {
  //     video.pause();
  //   }
  // };

  const handleMuteUnmuteClick = () => {
    setIsMuted(!isMuted);
    const video = document.querySelector("video");
    video.muted = !isMuted;
  };

  const handleArrowClick = () => {
    window.scroll({
      top: (window.innerHeight * 3) / 4,
      behavior: "smooth",
    });
  };
  return (
    <>
      <video
        className="bg-video"
        src={bg}
        autoPlay
        loop
        muted={true}
        data-aos="zoom-in"
      ></video>
      <div className="controls">
        {/* <div className="play-pause-icon">
          {isPlaying ? (
            <SlControlPause size={30} onClick={handlePlayPauseClick} />
          ) : (
            <SlControlPlay size={30} onClick={handlePlayPauseClick} />
          )}
        </div> */}
        <div
          data-aos="zoom-in-left"
          data-aos-anchor-placement="bottom-right"
          className="mute-unmute-icon "
        >
          {isMuted ? (
            <SlVolume2 size={30} onClick={handleMuteUnmuteClick} />
          ) : (
            <SlVolumeOff size={30} onClick={handleMuteUnmuteClick} />
          )}
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        className="arrow"
        onClick={handleArrowClick}
      >
        <SlArrowDown />
      </div>
    </>
  );
};

export default BackgroundVideo;
