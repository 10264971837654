import React, { useState, useEffect } from "react";
import SingleQuestion from "../../components/faq/index";
import QAdata from "./question";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.css";
import { meta } from "../../content_option";
import Footer from "../../components/footer";
import AOS from "aos";
import "aos/dist/aos.css";

const FAQ = () => {
  const [questions, setQuestions] = useState(QAdata);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <HelmetProvider>
      <Container className="faq-header pt-5">
        <Helmet>
          <meta charSet="utf-8" />
          <title> FAQ's| {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">FAQ's</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>

        <section className="container ">
          {questions.map(({ topic, questions, qid }) => {
            return (
              <div className="questionSection">
                <h3 key={qid}>{topic} : </h3>
                {questions.map((question, id) => (
                  <SingleQuestion
                    key={question.id}
                    {...question}
                    data-aos={id % 2 === 0 ? "fade-right" : "fade-left"}
                  />
                ))}
              </div>
            );
          })}
        </section>
      </Container>
      <Footer />
    </HelmetProvider>
  );
};
export default FAQ;
