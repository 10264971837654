import React from "react";
import "./style.css";

import { socialprofils } from "../../content_option";
import {
  SlSocialFacebook,
  SlSocialInstagram,
  SlSocialYoutube,
} from "react-icons/sl";

export const Socialicons = (params) => {
  return (
    <div data-aos="zoom-in-right" className="stick_follow_icon">
      <ul>
        {socialprofils.youtube && (
          <li>
            <a href={socialprofils.youtube}>
              <SlSocialYoutube />
            </a>
          </li>
        )}

        {socialprofils.facebook && (
          <li>
            <a href={socialprofils.facebook}>
              <SlSocialFacebook />
            </a>
          </li>
        )}

        {socialprofils.instagram && (
          <li>
            <a href={socialprofils.instagram}>
              <SlSocialInstagram />
            </a>
          </li>
        )}
        {/* {socialprofils.twitch && (
          <li>
            <a href={socialprofils.twitch}>
              <FaTwitch />
            </a>
          </li>
        )} */}
      </ul>
      <p>Follow Us</p>
    </div>
  );
};
