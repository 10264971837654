import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllDocuments } from "./api";

export const allDocument = createAsyncThunk(
  "allDocuments/docs",
  async (payload) => {
    try {
      const res = await getAllDocuments(payload);
      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);
