import { FaPlay } from "react-icons/fa";

const logotext = "Made In Heaven";
const meta = {
  title: "Made In Heaven",
  description:
    "MadeInHeaven offers professional wedding photography services to capture the special moments of your big day.",
};

const videos = [
  {
    title: "Sample Video 1",
    thumbnailUrl: "http://example.com/thumbnail1.jpg",
  },
  {
    title: "Sample Video 2",
    thumbnailUrl: "http://example.com/thumbnail2.jpg",
  },
];

const introdata = {
  title: "Made In Heaven",
  animated: {
    first: "We craft your visual love story",
    second: "Eternizing your memories, one frame",
    third: "Timeless art from your fairytale",
  },
  description:
    "Our team of experienced photographers use high-end equipment and techniques to create beautiful photographs that will be treasured for years to come. We offer a wide range of services including pre-wedding photoshoots, candid photography, traditional photography, and videography. We also provide a range of packages and options to suit different budgets and styles. By choosing us, you can trust that your wedding memories will be captured in the most beautiful and timeless way possible. The main focus of our service is on capturing the emotions, love and memories of the day in a creative and visually stunning way, so that you can cherish these memories for a lifetime ",
  // your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
  title: "a bit about my self",
  aboutme:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent quis dolor id ligula semper elementum feugiat pretium nulla. Nunc non commodo dolor. Nunc mollis dignissim facilisis. Morbi ut magna ultricies.",
};

const process = [
  {
    icon: <FaPlay />,
    step: "the beginning",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];
const worktimeline = [
  {
    jobtitle: "Designer of week",
    where: "YAdfi",
    date: "2020",
  },
  {
    jobtitle: "Designer of week",
    where: "Jamalya",
    date: "2019",
  },
  {
    jobtitle: "Designer of week",
    where: "ALquds",
    date: "2019",
  },
];

const skills = [
  {
    name: "Work Ethics",
    value: 99,
  },
  {
    name: "Quality Equipment",
    value: 100,
  },
  {
    name: "Reliability",
    value: 100,
  },
  {
    name: "Highlights",
    value: 97,
  },
  {
    name: "Recomendations",
    value: 95,
  },
];

const services = [
  {
    title: "Wedding Photoshoots",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Event Management",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Destination shoots",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
];

const contactConfig = {
  YOUR_EMAIL: "name@domain.com",
  YOUR_FONE: "(555)123-4567",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula eu nunc et sollicitudin. Cras pulvinar, nisi at imperdiet pharetra. ",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id",
};

const socialprofils = {
  // github: "https://github.com",
  instagram: "https://instagram.com",
  facebook: "https://facebook.com",
  // linkedin: "https://linkedin.com",
  // twitter: "https://twitter.com",
  youtube: "https://youtube.com",
  // twitch: "https://twitch.com",
};
export {
  meta,
  dataabout,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
  videos,
};
