import React, { useEffect } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta } from "../../content_option";
import { Link } from "react-router-dom";
import BackgroundVideo from "../../components/backgroundVideo";

import Process from "../../components/process";
import Footer from "../../components/footer";
import TestimonalComponent from "../../components/testimonal";
import { galleryData } from "../gallery/data";
import GridComponent from "../../components/grid";
import About2 from "../../components/aboutSection/about2";
import StaticAbout from "../../components/aboutSection/staticAbout";
import AOS from "aos";
import "aos/dist/aos.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { allDocument } from "../../features/imageUpload/imageUpload.action";
import { Box, CircularProgress } from "@material-ui/core";

export const Home = () => {
  const dispatch = useDispatch();
  const { allDoc, allDocLoading, allDocLoadingSucess } = useSelector(
    (state) => state.imageUpload
  );
  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);

  useEffect(() => {
    dispatch(allDocument());
  }, [dispatch]);

  return (
    <HelmetProvider>
      <TransitionGroup>
        <CSSTransition classNames="fade-in" timeout={1000}>
          <section id="home" className="home">
            <BackgroundVideo />
            <Helmet>
              <meta charSet="utf-8" />
              <title> {meta.title}</title>
              <meta name="description" content={meta.description} />
            </Helmet>

            <div className="intro_sec d-block d-lg-flex align-items-center ">
              <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
                <div className="align-self-center ">
                  <div className="intro mx-auto">
                    <h2 className="mb-1x">{introdata.title}</h2>
                    <h1 className="fluidz-48 mb-2">
                      <Typewriter
                        options={{
                          strings: [
                            introdata.animated.first,
                            introdata.animated.second,
                            introdata.animated.third,
                          ],
                          autoStart: true,
                          loop: true,
                          deleteSpeed: 10,
                        }}
                      />
                    </h1>
                    <p className="mb-1x">{meta.description}</p>
                    <div className="intro_btn-action pb-5">
                      <Link to="/gallery" className="text_2">
                        <div id="button_p" className="ac_btn btn ">
                          Films Gallery
                          <div className="ring one"></div>
                          <div className="ring two"></div>
                          <div className="ring three"></div>
                        </div>
                      </Link>
                      <Link to="/contact">
                        <div id="button_h" className="ac_btn btn">
                          Contact Me
                          <div className="ring one"></div>
                          <div className="ring two"></div>
                          <div className="ring three"></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CSSTransition>
      </TransitionGroup>
      {allDocLoading && (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      {allDocLoadingSucess && <GridComponent data={allDoc && allDoc} />}

      <Process />

      <StaticAbout />

      <TestimonalComponent />

      <Footer />
    </HelmetProvider>
  );
};
