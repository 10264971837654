import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import youtubeThumbnail from "youtube-thumbnail";
import VideoModal from "../videoModal";
import "./styles.css";
import AOS from "aos";
import "aos/dist/aos.css";

const GridComponent = ({ data }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000, // set the animation duration (in milliseconds)
    });
  }, []);

  const handleClose = () => setShowVideoModal(false);
  const handleShow = (id) => {
    setShowVideoModal(true);
    setVideoId(id);
  };

  // const trimData = data && data.sort(() => Math.random() - 0.5).slice(0, 10);

  return (
    <div className="container  mb-5 ">
      <Col lg="8 mb-5 mt-4">
        <h1 className="display-4 mb-4">Our Films</h1>
        <hr className="t_border my-4 ml-0 text-left" />
      </Col>
      <div className="gridContainerr ">
        {data &&
          data.slice(0, 10).map((data, i) => {
            const thumbnail = youtubeThumbnail(data.video_url).high.url;

            return (
              <div
                key={i}
                className={`grid-item-${i} po_item gridItem`}
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                <img src={thumbnail} alt="" />
                <div className="content">
                  <p>{data.description}</p>
                  <FaPlay
                    size={30}
                    onClick={handleShow.bind(this, data.video_url)}
                  />
                </div>
              </div>
            );
          })}
        <VideoModal
          id={videoId}
          show={showVideoModal}
          handleClose={handleClose}
          setShow={setShowVideoModal}
        />
      </div>
    </div>
  );
};

export default GridComponent;
