import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import "./styles.css";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";

import SwipeableViews from "react-swipeable-views";
import img from "../../assets/images/gallery/gallery1.jpg";
import { Col, Row } from "react-bootstrap";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const writers = [
  {
    image: img,
    name: "Mr.Akib3",
    job: "CEO of NAVATECH",
    para: `Mr.Akib is a pleasure to work with. Their ideas are creative, they
      came up with imaginative solutions to some tricky issues, their
      landscaping and planting contacts are equally excellent we have a
      beautiful but also manageable garden as a result. Thank you!`,
  },
  {
    image: img,
    name: "Mr.Akib2",
    job: "CEO of PLANTCITY",
    para: `Akib is a pleasure to work with. Their ideas are creative, they
        came up with imaginative solutions to some tricky issues, their
        landscaping and planting contacts are equally excellent we have a
        beautiful but also manageable garden as a result. Thank you!`,
  },
  {
    image: img,
    name: "Mr.Akib1",
    job: "CEO of WEBLOOP",
    para: `Mr.Akibis a pleasure to work with. Their ideas are creative, they
        came up with imaginative solutions to some tricky issues, their
        landscaping and planting contacts are equally excellent we have a
        beautiful but also manageable garden as a result. Thank you!`,
  },
];

const TestimonalComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // const maxSteps = writers.length;

  // const handleNext = () => {
  //   setActiveStep(activeStep === maxSteps - 1 ? 0 : activeStep + 1);
  // };
  // const handleBack = () => {
  //   setActiveStep(activeStep === 0 ? maxSteps - 1 : activeStep - 1);
  // };

  return (
    <>
      <div className="container testmonal">
        <Row className="mb-4 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Testimonal</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <AutoPlaySwipeableViews
          className="container mb-6 abc"
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={10000}
        >
          {writers.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <div className={classes.articleWrapper}>
                  <div className={classes.right}>
                    <p className={classes.p}>{writers[activeStep].para}</p>
                    <Box className={classes.info}>
                      <h3>{writers[activeStep].name}</h3>{" "}
                      <p>{writers[activeStep].job}</p>
                    </Box>
                  </div>
                  <div className={classes.left}>
                    <img
                      className={classes.img}
                      src={writers[activeStep].image}
                      alt=""
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>

        {/* <Box className={classes.owlInner}>
          {writers.map((i) => (
            // <div className={classes.owlDot}>
            <RadioButtonCheckedIcon />
            // </div>
          ))}
        </Box> */}
      </div>
    </>
  );
};

export default TestimonalComponent;

const useStyles = makeStyles((theme) => ({
  testimonalContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "30px",
      width: "100%",
    },
  },
  container: {
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
    "& .react-swipable-view-container": {
      display: "flex",
      justifyContent: "center",
    },
  },

  articleWrapper: {
    display: "flex",
    justifyContent: "space-around",
    width: "100% !important",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
      alignItems: "center",
      gap: "30px",
      width: "100%",
    },
  },
  left: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    position: "relative",
    gap: 10,
    "& img": {
      margin: "auto",
      height: 250,
      width: "350px",
      [theme.breakpoints.down("sm")]: {
        height: 250,
      },
    },
  },
  right: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    padding: "1vw",
    maxWidth: "",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      textAlign: "center",
    },
    "& p": {
      width: "80%",
    },
  },
}));
