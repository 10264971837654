import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import Img from "../../assets/images/aboutImg.jpg";
import {
  dataabout,
  meta,
  // worktimeline,
  // skills,
  services,
} from "../../content_option";
import Footer from "../../components/footer";
import TestimonalComponent from "../../components/testimonal";
import Process from "../../components/process";
import StaticAbout from "../../components/aboutSection/staticAbout";
import bgGiff from "../../assets/videos/aboutBg.mp4";

export const About = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title> About | {meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <div style={{ position: "relative" }}>
        <video
          // className="bgGif"
          src={bgGiff}
          autoPlay
          loop
          muted
          style={{
            height: "60vh",
            width: "100%",

            // position: "absolute",

            objectFit: "cover",
            // zIndex: " -1",
            top: 0,
          }}
        ></video>
        <Container className="About-header">
          <Row className="mb-4 mt-3">
            <Col lg="8">
              <h1 className="display-4 mb-4">About Us</h1>
              <hr className="t_border my-4 ml-0 text-left" />
            </Col>
          </Row>
          <Row className="sec_sp text">
            <img src={Img} alt="" />

            <Col lg="7" className="d-flex align-items-center">
              <div>
                <p>{dataabout.aboutme}</p>
              </div>
            </Col>
          </Row>
          <Process />
          <Row className="sec_sp services">
            <Col lang="5  " className="ser_head" data-aos="fade-left">
              <h3 className="color_sec  py-4">Our services</h3>
            </Col>
            <Col lg="7">
              {services.map((data, i) => {
                return (
                  <div className="service_ py-4" key={i}>
                    <h5 className="service__title">{data.title}</h5>
                    <p className="service_desc">{data.description}</p>
                  </div>
                );
              })}
            </Col>
          </Row>
          <StaticAbout />
          <TestimonalComponent />
        </Container>
      </div>
      <Footer />
    </HelmetProvider>
  );
};
