import React, { useState, useEffect } from "react";
import { FaPlay } from "react-icons/fa";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { galleryData } from "../../pages/gallery/data";
import "./styles.css";
import VideoModal from "../videoModal";
import { Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  once: true,
});

var youtubeThumbnail = require("youtube-thumbnail");

const ReactMasonry = ({ data, rows }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    AOS.refresh();
  }, [data]);

  const handleClose = () => setShowVideoModal(false);
  const handleShow = (id) => {
    setShowVideoModal(true);
    setVideoId(id);
  };

  return (
    <div className="masnoryContainer container">
      <Col lg="8 mb-5 mt-4">
        <h1 className="display-4 mb-4">Our Films</h1>
        <hr className="t_border my-4 ml-0 text-left" />
      </Col>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry columnsCount={2} gutter="1.5vw" className="grid-container">
          {data.map((item) => {
            const thumbnail = youtubeThumbnail(item.video_url).high.url;
            const className = item.id % 2 === 0 ? "col-span-2" : "col-span-1";
            return (
              <div
                key={item._id}
                className={`po_item ${className}`}
                data-aos="zoom-in"
              >
                <img src={thumbnail} alt="" />
                <div className="content">
                  <p>{item.desctiption}</p>
                  <FaPlay
                    size={30}
                    onClick={() => handleShow(item.video_url)}
                  />
                </div>
              </div>
            );
          })}
        </Masonry>
        <VideoModal
          id={videoId}
          show={showVideoModal}
          handleClose={handleClose}
          setShow={setShowVideoModal}
        />
      </ResponsiveMasonry>
    </div>
  );
};

export default ReactMasonry;
