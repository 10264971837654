import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { socialprofils } from "../../content_option";
import {
  SlSocialFacebook,
  SlSocialInstagram,
  SlSocialYoutube,
} from "react-icons/sl";

const Footer = () => {
  return (
    <>
      <div className="container">
        <hr className="t_border my-4 ml-0 text-left" />
        <div className="mainfooter">
          <div className="blog">
            {" "}
            <h4>FOLOW OUR WORK</h4>
            <p>
              want to stay upto date with all our
              <br /> work? here is our social media
            </p>
            <ul className="SocialIcons">
              {socialprofils.youtube && (
                <li>
                  <a href={socialprofils.youtube}>
                    <SlSocialYoutube />
                  </a>
                </li>
              )}

              {socialprofils.facebook && (
                <li>
                  <a href={socialprofils.facebook}>
                    <SlSocialFacebook />
                  </a>
                </li>
              )}

              {socialprofils.instagram && (
                <li>
                  <a href={socialprofils.instagram}>
                    <SlSocialInstagram />
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="blog mid">
            <h3>Made in Heaven</h3>
            <ul className="mid linkss">
              <Link to="/about">About Us</Link>
              <Link to="/faqs">FAQ's</Link>
              <Link to="/gallery">Films</Link>
            </ul>
          </div>
          <div className="blog">
            <h4>GET IN TOUCH</h4>
            <p>
              Have a question? Email us at <br />
              info@madeinheaven or use our form
            </p>
            <Link to="/contact">
              <div id="button_h" className="ac_btn btn lastbtn">
                Contact Us
                <div className="ring one"></div>
                <div className="ring two"></div>
                <div className="ring three"></div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
