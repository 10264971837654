import React, { useEffect, useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";
import { galleryData } from "./data";
import Footer from "../../components/footer";
import ReactMasonry from "../../components/masnory";
import { useDispatch, useSelector } from "react-redux";
import { allDocument } from "../../features/imageUpload/imageUpload.action";
import { Box, CircularProgress } from "@material-ui/core";

export const Gallery = () => {
  const dispatch = useDispatch();
  const { allDoc, allDocLoading, allDocLoadingSucess } = useSelector(
    (state) => state.imageUpload
  );

  useEffect(() => {
    dispatch(allDocument());
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Container className="gallery-header pt-5">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Films | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        {allDocLoading && (
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}

        {allDocLoadingSucess && (
          <ReactMasonry data={allDoc && allDoc} rows="2" />
        )}
      </Container>

      <Footer />
    </HelmetProvider>
  );
};
