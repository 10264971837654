import { object, string, number } from "yup";

export const contactUsSchema = object().shape({
  name: string().required("Name is required"),
  partnerName: string().required("Partner Name is required"),
  email: string().required("Must include @ and . ").email(),
  instagram: string().matches(
    /^(?:(?:@)[a-zA-Z0-9]+)/,
    "Invalid Instagram handle"
  ),
  phone: number().required("Please enter a valid phone number").integer(),
  weddingDate: string()
    .required("Wedding date is required")
    .matches(
      /^(0?[1-9]|[12][0-9]|3[01])/ - /-\d\d$/,
      "Invalid date format, should be DD/MM/YYYY"
    ),
  receptionDate: string()
    .required("Reception date is required")
    .matches(
      /^(0?[1-9]|[12][0-9]|3[01])/ - /-\d\d$/,
      "Invalid date format, should be DD/MM/YYYY"
    ),
  guestCount: number().integer(),
  photographer: string(),
  yourStory: string().required("Please tell us your story"),
  sthAboutUs: string().required("What made you interested in us?"),
  budget: number().integer(),
});
