import React from "react";
import { Link } from "react-router-dom";
import g1 from "../../assets/images/gallery/gw1.jpg";
import "./styles.css";
const StaticAbout = () => {
  return (
    <div className="container staticAboutContainer mb-5 pb-5">
      <div className="third">
        <div className="imgContainer" data-aos="fade-left">
          <img src={g1} alt="img" className="img21" />
        </div>
        <div className="inners">
          <h3>Our Journey</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur id
            quod quidem iure accusantium voluptates delectus a, dicta ut! Lorem
            ipsum dolor sit amet, consectetur adipisicing elit. Tenetur id quod
            quidem iure accusantium voluptates delectus a, dicta ut! Lorem ipsum
            dolor sit amet, consectetur adipisicing elit. Tenetur id quod quidem
            iure accusantium voluptates delectus a, dicta ut!
          </p>
        </div>
      </div>
      <div className="second oo ">
        <div className="inners">
          <h3>Our Journey</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur id
            quod quidem iure accusantium voluptates delectus a, dicta ut! Lorem
            ipsum dolor sit amet, consectetur adipisicing elit. Tenetur id quod
            quidem iure accusantium voluptates delectus a, dicta ut! Lorem ipsum
            dolor sit amet, consectetur adipisicing elit. Tenetur id quod quidem
            iure accusantium voluptates delectus a, dicta ut!
          </p>
        </div>
        <div className="imgContainer" data-aos="fade-right">
          <img src={g1} alt="img" className="img21" />
        </div>
      </div>
    </div>
  );
};

export default StaticAbout;
