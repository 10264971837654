import { createSlice } from "@reduxjs/toolkit";
import { allDocument } from "./imageUpload.action";

const initialState = {
  allDoc: [],
  allDocLoading: false,
  allDocLoadingSucess: false,
  allDocLoadingFailed: false,
};

const imageSlicer = createSlice({
  name: "imageUpload/upload",
  initialState: initialState,

  extraReducers: {
    [allDocument.pending]: (state) => {
      state.allDocLoading = true;
      state.allDocLoadingSucess = false;
      state.allDocLoadingFailed = false;
    },
    [allDocument.fulfilled]: (state, action) => {
      state.allDocLoadingSucess = true;
      state.allDocLoadingFailed = false;
      state.allDocLoading = false;
      state.allDoc = action.payload?.data?.result;
    },
    [allDocument.rejected]: (state) => {
      state.allDocLoadingFailed = true;
      state.allDocLoadingSucess = false;
      state.allDocLoading = false;
    },
  },
});

export default imageSlicer.reducer;
