import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import "./styles.css";
import gallery1 from "../../assets/images/gallery/gallery1.jpg";
import gallery2 from "../../assets/images/gallery/gallery2.jpg";
import gallery3 from "../../assets/images/gallery/gallery3.jpg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import ImgComp from "./image";
const Highlight = () => {
  let arr = [
    <ImgComp src={gallery1} />,
    <ImgComp src={gallery2} />,
    <ImgComp src={gallery3} />,
  ];
  const [x, setX] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(rightBtn, 3000);
    return () => clearInterval(intervalId);
  }, []);

  const leftBtn = () => {
    x === 0 ? setX(-100 * (arr.length - 1)) : setX(x + 100);
  };
  const rightBtn = () => {
    x === -100 * (arr.length - 1) ? setX(0) : setX(x - 100);
  };

  return (
    <div className="container1">
      <div className="wrapper">
        {arr.map((item, index) => {
          return (
            <div
              key={index}
              className="slide"
              style={{ transform: `translateX(${x}%)` }}
            >
              {item}
            </div>
          );
        })}
        <button id="leftBtn" onClick={leftBtn}>
          <FaChevronLeft />
        </button>
        <button id="rightBtn" onClick={rightBtn}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Highlight;
