import React, { useState } from "react";
import * as emailjs from "emailjs-com";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { meta } from "../../content_option";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { contactConfig } from "../../content_option";
import { TfiEmail } from "react-icons/tfi";
import { SlPhone } from "react-icons/sl";
import { useFormik } from "formik";
import { contactUsSchema } from "./schema";

export const ContactUs = () => {
  const [formData, setFormdata] = useState({
    loading: false,
    show: false,
    alertmessage: "",
    variant: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormdata({ loading: true });

    const templateParams = {
      from_name: formData.email,
      user_name: formData.yourname,
      to_name: contactConfig.YOUR_EMAIL,
      message: formData.message,
    };

    emailjs
      .send(
        contactConfig.YOUR_SERVICE_ID,
        contactConfig.YOUR_TEMPLATE_ID,
        templateParams,
        contactConfig.YOUR_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormdata({
            loading: false,
            alertmessage: "SUCCESS! ,Thankyou for your messege",
            variant: "success",
            show: true,
          });
        },
        (error) => {
          console.log(error.text);
          setFormdata({
            alertmessage: `Faild to send!,${error.text}`,
            variant: "danger",
            show: true,
          });
          document.getElementsByClassName("co_alert")[0].scrollIntoView();
        }
      );
  };

  const { handleChange, handleBlur, errors, touched } = useFormik({
    initialValues: {
      name: "",
      partnerName: "",
      email: "",
      instagram: "",
      phone: "",
      weddingDate: "",
      receptionDate: "",
      guestCount: "",
      photographer: "",
      yourStory: "",
      sthAboutUs: "",
      budget: "",
    },
    validationSchema: contactUsSchema,
    onSubmit: async (values) => {},
  });
  console.log(errors);
  return (
    <HelmetProvider>
      <Container className="pt-5">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title} | Contact</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Contact Me</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="12">
            <Alert
              //show={formData.show}
              variant={formData.variant}
              className={`rounded-0 co_alert ${
                formData.show ? "d-block" : "d-none"
              }`}
              onClose={() => setFormdata({ show: false })}
              dismissible
            >
              <p className="my-0">{formData.alertmessage}</p>
            </Alert>
          </Col>
          <Col lg="5" className="mb-5">
            <h3 className="color_sec py-4">Get in touch</h3>
            <address>
              <TfiEmail />{" "}
              <a href={`mailto:${contactConfig.YOUR_EMAIL}`}>
                {contactConfig.YOUR_EMAIL}
              </a>
              <br />
              <br />
              {contactConfig.hasOwnProperty("YOUR_FONE") ? (
                <p>
                  <SlPhone /> {contactConfig.YOUR_FONE}
                </p>
              ) : (
                ""
              )}
            </address>
            <p>{contactConfig.description}</p>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <form onSubmit={handleSubmit} className="contact__form w-100">
              <Row>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control rounded-0"
                    id="name"
                    name="name"
                    placeholder="Your Name"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control rounded-0"
                    name="partnerName"
                    placeholder="Your Partner's name"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Col>

                <Col lg="6" className="form-group">
                  <input
                    className="form-control "
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control rounded-0"
                    name="instagram"
                    placeholder="Instagram"
                    type="text"
                    onChange={handleChange}
                  />
                </Col>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control"
                    name="phone"
                    placeholder="Phone Number"
                    type="string"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control"
                    id="photographer"
                    name="photographer"
                    placeholder="Who is your Photographer?"
                    type="text"
                    onChange={handleChange}
                  />
                </Col>
                <Col lg="12" className="form-group">
                  <input
                    className="form-control rounded-0"
                    name="weddingDate"
                    placeholder="Wedding Date and Location"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.weddingDate && <p>{errors.weddingDate}</p>}
                </Col>
                <Col lg="12" className="form-group">
                  <input
                    className="form-control rounded-0"
                    name="receptionDate"
                    placeholder="Reception Date and Location"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Col>

                <Col lg="6" className="form-group">
                  <input
                    className="form-control rounded-0"
                    name="guestCount"
                    placeholder="Guest Count"
                    type="text"
                    onChange={handleChange}
                  />
                </Col>

                <Col lg="6" className="form-group">
                  <input
                    className="form-control rounded-0"
                    name="budget"
                    placeholder="Your Approximate Budget"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg="12" className="form-group">
                  <textarea
                    className="form-control rounded-0"
                    name="yourStory"
                    placeholder="Let's hear your Story"
                    rows="3"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </Col>

                <Col lg="12" className="form-group">
                  <textarea
                    className="form-control rounded-0"
                    name="sthAboutUs"
                    placeholder="Something about us"
                    rows="3"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </Col>
              </Row>

              <br />
              <Row>
                <Col lg="12" className="form-group">
                  <button className="btn ac_btn" type="submit">
                    {formData.loading ? "Sending..." : "Send"}
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
      <div className={formData.loading ? "loading-bar" : "d-none"}></div>
    </HelmetProvider>
  );
};
