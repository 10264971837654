import React from "react";
import Typewriter from "typewriter-effect";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./App.css";

const Preloader = () => {
  return (
    <TransitionGroup>
      <CSSTransition timeout={6000} classNames="fade">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            fontSize: "23px",
          }}
        >
          <Typewriter
            options={{
              strings: ["Made in Heaven"],
              autoStart: true,
              loop: true,
            }}
          />
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Preloader;
