import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { galleryData } from "../../pages/gallery/data";
import { TfiClose } from "react-icons/tfi";
import { useSelector } from "react-redux";

const VideoModal = ({ show, handleClose, id, setShow }) => {
  // const selectedVideo =
  //   id &&
  //   allDocLoadingSucess &&
  //   allDoc &&
  //   allDoc.filter((item) => {
  //     return item.id === id;
  //   })[0].video_url;

  return (
    <Modal
      show={show}
      centered
      onHide={handleClose}
      backdrop="static"
      fullscreen
      dialogClassName="modal"
      keyboard
    >
      <Button variant="link" onClick={handleClose} className="close-button">
        <TfiClose size={25} />
      </Button>

      <Modal.Body className="modalBody">
        <ReactPlayer
          url={id && id}
          controls
          muted
          config={{
            youtube: {
              playerVars: { showinfo: 1 },
            },
          }}
          height="100%"
          width="100%"
        />
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
