import React from "react";
import { VscCalendar, VscBookmark } from "react-icons/vsc";
import { SlCallIn } from "react-icons/sl";
import { Col } from "react-bootstrap";
import "./style.css";

const Process = () => {
  return (
    <div className="processContainer container">
      <Col lg="8 mb-4 mt-4">
        <h1 className="display-4 mb-4">The Process</h1>
        <hr className="t_border my-4 ml-0 text-left" />
      </Col>
      <div className="inner sec_sp">
        <div className="availability card1" data-aos="fade-right">
          <SlCallIn />
          <h4>Drop your query</h4>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Esse
            praesentium corporis maxime,
          </p>
        </div>
        <div className="consult card1" data-aos="fade-up">
          <VscCalendar />
          <h4>Schedule a Meeting</h4>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Esse
            praesentium corporis maxime,
          </p>
        </div>
        <div className="dates card1" data-aos="fade-left">
          <VscBookmark />
          <h4>Book the Date</h4>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Esse
            praesentium corporis maxime,
          </p>
        </div>
      </div>
    </div>
  );
};

export default Process;
