const QAdata = [
  {
    topic: "Work",
    questions: [
      {
        question: "How do you define your style?",
        answer:
          "Our style is realistic and discreet with a focus on narrative and Indie Film techniques.",
      },
      {
        question: "How do we book you?",
        answer:
          "Please fill out the form on the CONTACT page or email us. We work on a first come, first serve basis and require a booking deposit due to Covid-19.",
      },
      {
        question: "How many people do you bring along?",
        answer:
          "A team of two cinematographers is sufficient for 500 guest weddings, but the team size can be adjusted based on your requirements.",
      },
    ],
  },
  {
    topic: "Travel",
    questions: [
      {
        question: "Where are you based? Do you travel for weddings?",
        answer:
          "We are based in Vancouver, Canada and do travel for weddings. We have documented weddings in various locations.",
      },
    ],
  },
  {
    topic: "Pricing & Delivery",
    questions: [
      {
        question: "How do you deliver films?",
        answer:
          "We deliver films on 2 USB sticks and an online link which is valid for 3 weeks. It is important to backup and save your investment.",
      },
      {
        question: "Do you provide Raw footage?",
        answer: "No, we do not provide Raw footage.",
      },
      {
        question: "When do we get our wedding films?",
        answer:
          "Wedding films are delivered within 3-4 months, with communication in special cases where more time is needed.",
      },
      {
        question: "How long do you keep a copy of our Wedding footage?",
        answer:
          "We keep a copy for 45 days after final delivery, after which it will be deleted.",
      },
    ],
  },
  {
    topic: "Booking & Contact",
    questions: [
      {
        question:
          "Woo-hoo, we are now more excited than ever, we are ready to book, what do we do now?",
        answer:
          "Book a consultation with us to go in depth about your wedding and secure your booking.",
      },
    ],
  },
];

export default QAdata;
